html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
}

.MuiFormControl-root {
    width: 100%;
    box-sizing: border-box;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

.react-pdf__Page__textContent, .react-pdf__Page__annotations{
    display: none;
}
